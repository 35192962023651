import { Config } from '@/services/ConfigService'
import { Configurator } from '@/plugins/axios/Configurator'

const axios = Configurator.defaultConfiguration(Config.VUE_APP_ROOT_API + '/api/')

const addAccountant = (accountingFirmId, data) => {
	const url = `accounting-firms/${accountingFirmId}/users`
	return axios.post(url, data, { show_error: false })
}

const deleteAccountant = (accountingFirmId, userId) => {
	const url = `accounting-firms/${accountingFirmId}/users/${userId}`
	return axios.delete(url)
}

const getAccountants = (accountingFirmId, params = {}) => {
	const url = `accounting-firms/${accountingFirmId}/users`
	return axios.get(url, params)
}

const getClients = (accountingFirmId, params = {}, cancelToken = null) => {
	if (cancelToken) {
		cancelToken.cancel('Request cancelled')
	}
	const newCancelToken = Configurator.CancelToken.source()
	const url = `accounting-firms/${accountingFirmId}/vendors`
	return {
		cancelToken: newCancelToken,
		promise: axios.get(url, { cancelToken: newCancelToken.token, params })
	}
}

const getRoles = accountingFirmId => {
	const url = `accounting-firms/${accountingFirmId}/roles`
	return axios.get(url)
}

const getUserClients = (accountingFirmId, accountantId, params) => {
	const url = `accounting-firms/${accountingFirmId}/accountants/${accountantId}/vendors`
	return axios.get(url, { params })
}

const sendMail = (accountingFirmId, userId) => {
	const url = `accounting-firms/${accountingFirmId}/accountants/${userId}/mail`
	return axios.post(url)
}

const addUsersToCompany = (accountingFirmId, vendorId, params) => {
	const url = `accounting-firms/${accountingFirmId}/vendors/${vendorId}/accountants`
	return axios.post(url, params)
}

const addUserToCompanies = (accountingFirmId, userId, params) => {
	const url = `accounting-firms/${accountingFirmId}/accountants/${userId}/vendors`
	return axios.post(url, params)
}

const removeUsersFromCompany = (accountingFirmId, vendorId, params) => {
	const url = `accounting-firms/${accountingFirmId}/vendors/${vendorId}/accountants`
	return axios.delete(url, { params })
}

const removeUserFromCompanies = (accountingFirmId, userId, params) => {
	const url = `accounting-firms/${accountingFirmId}/accountants/${userId}/vendors`
	return axios.delete(url, { params })
}

const updateAccountantAccess = (accountingFirmId, accountantId, data) => {
	const url = `accounting-firms/${accountingFirmId}/accountants/${accountantId}/access`
	return axios.patch(url, data)
}

const updateAccountantThemes = (accountingFirmId, accountantId, data) => {
	const url = `accounting-firms/${accountingFirmId}/accountants/${accountantId}/themes`
	return axios.patch(url, data)
}

const updateAccountantRole = (accountingFirmId, accountantId, data) => {
	const url = `accounting-firms/${accountingFirmId}/accountants/${accountantId}/role`
	return axios.patch(url, data)
}

const listUsers = (data, config = {}, cancelToken) => {
	if (cancelToken) {
		cancelToken.cancel('Request cancelled')
	}

	const newCancelToken = Configurator.CancelToken.source()
	const url = `accounting-firms/${data.accounting_firm_id}/users`

	return {
		cancelToken: newCancelToken,
		promise: axios.get(url, {
			cancelToken: newCancelToken.token,
			config
		})
	}
}

const getUserByEmail = email => {
	const url = `users`
	return axios.get(url, { params: {email}, show_error: false })
}

export default {
	addAccountant: addAccountant,
	deleteAccountant: deleteAccountant,
	getAccountants: getAccountants,
	getClients: getClients,
	getRoles: getRoles,
	getUserClients: getUserClients,
	sendMail: sendMail,
	addUsersToCompany,
	addUserToCompanies,
	removeUsersFromCompany,
	removeUserFromCompanies,
	updateAccountantAccess: updateAccountantAccess,
	updateAccountantRole: updateAccountantRole,

	listUsers,
	updateAccountantThemes: updateAccountantThemes,
	getUserByEmail: getUserByEmail
}
